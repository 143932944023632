/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
import { UserType } from './UserTypes'
import { CurrencyItemType } from './ReportsTypes'
import { TransactionItemType } from './TransactionsTypes'
import { EntityFileType, PlatformType } from './EntityTypes'
import { BusinessDataSourceItemType } from './BusinessDataTypes'
import { ChartOfAccountImportMappingGroupType } from './LedgerTypes'

export interface SourceProviderType {
  providerId: number
  name: string
  logo: string
  slug: string
}

export interface TotalPositionType {
  entityAccount: EntityAccountType
  platformType: string
  provider: SourceProviderType
  accountType: string
  category: string
  currency: string
  amount: number
  inUSDT: number
  datetime: string
}

export interface EntityAccountPlatformType extends PlatformType {
  isEvm: boolean
}

export interface EntityAccountType {
  entityAccountId: string
  name: string
  entityId: string
  identity: string
  platformId: string
  platformType: string
  memo: string
  status: string
  platform: EntityAccountPlatformType
  entityAccountTagList?: string[]
  accountLevel: string // MASTER | SUB | NONE
  masterEntityAccountId: string
  masterEntityAccount: EntityAccountType | null
}

export interface SourceType {
  sourceId: string
  type: string
  name: string
  platformId: string
  updatedAt: string
  syncStatus: string
  integration: any
  integrationId: string
  platform: PlatformType
  transactionCount: number
  withBalance: boolean
  withTransaction: boolean
  provider: SourceProviderType
}

export interface ThirdPartyOpenAuthType {
  thirdPartyOpenAuthDataId: string
  type: string
  status: string // INIT | INCOMPLETE | COMPLETED
  generationTime: string
  extra: {
    [key: string]: any
  }
}

export interface treasuryBalanceItemType {
  currency: string
  amount: number
  amountFC: number
  underlyingCurrency: CurrencyItemType
}

export interface RecentlyBalanceParamsType {
  date: string
  subGroup: string
  sort: string
  auxiliaryTypeId?: string
  combineMasterSubAccount: boolean
}

export interface TreasuryAccountFilterType {
  entityId: string
  total: number
  data: RecentlyBalanceParamsType
}

export interface TradeCalculatePositionParamsType {
  date: string
  subGroup: string
  group: string
}

export interface TradeCalculatePositionFilterType {
  entityId: string
  total: number
  data: TradeCalculatePositionParamsType
}

export interface DeFiHoldingPositionParamsType {
  date: string
  subGroup: string
  group: string
  entityAccountIds?: string[]
  defiHoldingProtocolIds?: string
  typeList?: string
}

export interface DeFiHoldingPositionFilterType {
  entityId: string
  total: number
  data: DeFiHoldingPositionParamsType
}

export interface TreasuryBalanceVerifyTaskFilterFilterItemType {
  entityAccountId: string[]
  currency: string[]
  startDatetime: string[]
  endDatetime: string[]
  treasuryBalanceVerifyReasonId: string[]
}

export interface TreasuryBalanceVerifyTaskFilterFilterType {
  entityId: string
  type: string
  total: number
  data: TreasuryBalanceVerifyTaskFilterFilterItemType
}

export interface EntityAccountDetailType extends EntityAccountType {
  sourceList: SourceType[]
  changeAddressList: string[]
  treasuryBalanceList: treasuryBalanceItemType[]
}

export interface TreasuryBalanceCurrencyType {
  amount: number
  amountFC: number
  failCount: number
  currency: string
  underlyingCurrency: CurrencyItemType
}

export interface TreasuryBalanceItemType {
  currencyList: TreasuryBalanceCurrencyType[]
  list?: TreasuryBalanceItemType[]
  entityAccount: EntityAccountType
  totalFC: number
}

export interface TreasuryBalanceListItemType {
  list: TreasuryBalanceItemType[]
  currencyList: TreasuryBalanceCurrencyType[]
  platformType: string
  totalCount: string
}

export interface TreasuryBalanceListType {
  list: TreasuryBalanceListItemType[]
  total: {
    currencyList: TreasuryBalanceCurrencyType[]
    totalFC: number
  }
}

export enum TreasuryBalanceVerificationTaskStatusType {
  PENDING = 'PENDING',
  PASSED = 'PASSED'
}

export interface TreasuryBalanceVerifyReasonType {
  treasuryBalanceVerifyReasonId: string
  entityId: string
  type: string // SYSTEM | USER
  name: string
}

export interface TreasuryBalanceVerificationItemType {
  treasuryBalancePeriodId: string
  treasuryBalancePeriodNo: string
  entityId: string
  entityAccountId: string
  entityAccount: EntityAccountType
  currency: string
  underlyingCurrency: CurrencyItemType
  beginSourceId: string | null
  beginDatetime: string
  endDatetime: string
  beginBalance: string
  transactionInflowAmount: string
  transactionOutflowAmount: string
  calculateEndBalance: string
  endBalance: string
  status: string // SUCCESS | FAIL
  difference: string
  differenceFC: string
  explainByUserId: string
  explainByUser: UserType | null
  treasuryBalanceVerifyReasonId: string
  explainDatetime: string | null
  treasuryBalanceVerifyReason: TreasuryBalanceVerifyReasonType | null
}

export interface TreasuryBalanceVerificationListType {
  filteredCount: number
  list: TreasuryBalanceVerificationItemType[]
}

export interface TreasuryBalanceVerificationTaskStatType {
  pendingTotal: number
  passedTotal: number
}

export interface TreasuryBalanceVerificationDetailType extends TreasuryBalanceVerificationItemType {
  beginSourceId: string
  beginSource: SourceType
  endSourceId: string
  endSource: SourceType
  isTransferDeleted: boolean
  isTransferAdded: boolean
}

export interface TreasuryBalanceVerificationTransferListType {
  list: TransactionItemType[]
  totalCount: number
}

export enum TreasuryBalanceVerificationTaskOutsideDirectionType {
  PREVIOUS = 'PREVIOUS',
  SUBSEQUENT = 'SUBSEQUENT'
}

export interface TradeCalculatePositionListType {
  asset: any
  positionSide: string
  balance: number
  entityAccount?: EntityAccountType
  derivativeType?: string
  list?: TradeCalculatePositionListType[]
}

export interface TradeCalculatePositionType {
  derivativeType?: string
  entityAccount?: EntityAccountType
  list: TradeCalculatePositionListType[]
}

export interface DeFiHoldingTotalType {
  totalSupplyBalanceFC: string
  totalRewardBalanceFC: string
  totalBorrowBalanceFC: string
  totalFC: string
}

export interface DeFiHoldingProtocolType {
  defiHoldingProtocolId: string
  name: string
  logo: string
}

export interface DeFiHoldingPoolListType extends DeFiHoldingTotalType {
  type: string
  poolId: string
  pool: string[] // symbol
  poolShowSymbol: string[] // showSymbol
  currencyList: CurrencyItemType[]
}

export interface DeFiHoldingAccountListType extends DeFiHoldingTotalType {
  entityAccountId: string
  entityAccount: EntityAccountType
  list: DeFiHoldingPoolListType[] | DeFiHoldingProtocolListType[] | DeFiHoldingTypeListType[]
}

export interface DeFiHoldingTypeListType extends DeFiHoldingTotalType {
  type: string
  list: DeFiHoldingPoolListType[] | DeFiHoldingProtocolListType[] | DeFiHoldingAccountListType[]
}

export interface DeFiHoldingProtocolListType extends DeFiHoldingTotalType {
  name: string
  protocol: DeFiHoldingProtocolType
  list: DeFiHoldingPoolListType[] | DeFiHoldingTypeListType[] | DeFiHoldingAccountListType[]
}

export interface DeFiHoldingListType extends DeFiHoldingTotalType {
  entityAccountId?: string
  entityAccount?: EntityAccountType
  protocol?: DeFiHoldingProtocolType
  type?: string
  list: DeFiHoldingTypeListType[] | DeFiHoldingAccountListType[] | DeFiHoldingPoolListType[]
}

export interface DeFiHoldingType {
  totalCount: DeFiHoldingTotalType
  list: DeFiHoldingListType[]
}

export interface DeFiHoldingDetailType extends DeFiHoldingTotalType {
  underlyingCurrency: CurrencyItemType
  supplyBalance: string
  rewardBalance: string
  borrowBalance: string
}

export interface SourceItemType extends SourceType {
  createdAt: string
  entityAccountList: EntityAccountType[]
}

export interface SourceListType {
  list: SourceItemType[]
  total: number
}

export interface JournalSourceItemType {
  externalJournalSourceId: string
  entityId: string
  chartOfAccountImportMappingGroupId: string
  name: string
  template: string
  timezone: string
  totalCount: number
  recognizedCount: number
  method: string
  entityFileId: string
  userId: string
  syncStatus: string
  syncDoneTime: string | null
  chartOfAccountImportMappingGroup: ChartOfAccountImportMappingGroupType
  entityFile: EntityFileType
  user: string
}

export interface JournalSourceListType {
  list: JournalSourceItemType[]
  total: number
}

export interface CounterpartyType {
  counterpartyId: string
  entityId: string
  type: string
  name: string
  tel: string
  email: string
  location: string
  memo: string
}

export interface CounterpartyListType {
  total: number
  list: CounterpartyType[]
}

export interface CounterpartyTagType {
  counterpartyTagId: string
  entityId: string
  name: string
}

export interface ContactItemType {
  contactId: string
  entityId: string
  counterpartyId: string
  identity: string
  platformId: string
  transactionCount: number
  platform: PlatformType
  counterparty: CounterpartyType
  tags: CounterpartyTagType[]
}

export interface ContactListType {
  list: ContactItemType[]
  total: number
}
export interface ContactIdentificationType {
  counterpartyIdentificationId: string
  name: string
  code: string
}

export interface CounterpartyDetailType extends CounterpartyType {
  contacts: ContactItemType[]
  tags: CounterpartyTagType[]
  type: string
  platform: PlatformType | null
  identifications: ContactIdentificationType[]
}

export interface EntitySourceListType {
  total: number
  list: (SourceItemType | BusinessDataSourceItemType | JournalSourceItemType)[]
}
