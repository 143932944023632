<template>
  <el-dialog
    v-model="showExplainDialog"
    width="30%"
    align-center
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-treasury-balance-verification-explanation-dialog"
    @close="onCloseExplainDialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-treasury-balance-verification-explanation-dialog-header__title">
        {{ transformI18n($t('title.acceptAndExplainBalanceDiscrepancies')) }}
      </h4>
    </template>

    <el-form
      ref="explanationReasonFormRef"
      v-loading="loading"
      :model="explanationReasonForm"
      :rules="rules"
      label-position="top"
      class="elv-reconciliation-explain-form"
      @submit.prevent
    >
      <el-form-item :label="transformI18n($t('reconciliation.reason'))" prop="treasuryBalanceVerifyReasonId">
        <ElvSelect
          v-model="explanationReasonForm.treasuryBalanceVerifyReasonId"
          :placeholder="`${t('common.select')}...`"
          width="572px"
          filterable
          allow-create
          default-first-option
          :reserve-keyword="false"
          :options="reasonOptions"
          :popper-append-to-body="false"
        />
      </el-form-item>
    </el-form>
    <p class="elv-reconciliation-explain-form-add-desc">
      <SvgIcon name="help" width="12" height="12" :fill="'#838d95'" /><span>{{
        t('message.addExplainBalanceDiscrepanciesTipMessage')
      }}</span>
    </p>
    <template #footer>
      <elv-button
        height="44"
        width="100"
        round
        type="primary"
        :disabled="loading"
        :loading="submitLoading"
        @click="onSaveExplain"
      >
        {{ t('button.save') }}</elv-button
      >
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { ElMessage } from 'element-plus'
import AccountsApi from '@/api/AccountsApi'
import { $t, transformI18n } from '@/i18n/index'
import { cloneDeep, find, isEmpty } from 'lodash-es'
import type { FormInstance, FormRules } from 'element-plus'
import { useAccountStore } from '@/stores/modules/accounts'
import { TreasuryBalanceVerificationDetailType } from '#/AccountsTypes'

const props = defineProps({
  currentData: {
    type: Object as () => TreasuryBalanceVerificationDetailType,
    default: () => {
      return {} as TreasuryBalanceVerificationDetailType
    }
  }
})

const { t } = useI18n()
const route = useRoute()

const accountStore = useAccountStore()
const emit = defineEmits(['onResetList'])

const loading = ref(false)
const submitLoading = ref(false)
const showExplainDialog = ref(false)
const explanationReasonForm = ref({
  treasuryBalanceVerifyReasonId: ''
})
const explanationReasonFormRef = useTemplateRef<FormInstance>('explanationReasonFormRef')

const rules = reactive<FormRules>({
  treasuryBalanceVerifyReasonId: {
    trigger: 'blur',
    required: true,
    message: 'Please select a reason'
  }
})

const entityId = computed(() => {
  return route.params?.entityId as string
})

const reasonOptions = computed(() => {
  return accountStore.treasuryBalanceVerifyReasonList
    .filter((filterItem) => filterItem.type !== 'SYSTEM')
    .map((item) => {
      return {
        value: item.treasuryBalanceVerifyReasonId,
        label: item.name
      }
    })
})

// 切换弹窗展示
const onCheckExplainDialog = () => {
  showExplainDialog.value = !showExplainDialog.value
  if (
    showExplainDialog.value &&
    props.currentData.treasuryBalanceVerifyReasonId &&
    props.currentData.treasuryBalanceVerifyReasonId !== '0'
  ) {
    explanationReasonForm.value.treasuryBalanceVerifyReasonId = props.currentData.treasuryBalanceVerifyReasonId
  }
}

/**
 * @description: 保存解释
 */
const onSaveExplain = async () => {
  explanationReasonFormRef.value?.validate(async (valid: boolean) => {
    if (valid) {
      try {
        submitLoading.value = true
        const data: any = cloneDeep(explanationReasonForm.value)
        const reason = find(accountStore.treasuryBalanceVerifyReasonList, {
          treasuryBalanceVerifyReasonId: data.treasuryBalanceVerifyReasonId
        })

        if (isEmpty(reason)) {
          data.name = data.treasuryBalanceVerifyReasonId
          delete data.treasuryBalanceVerifyReasonId
        } else {
          delete data.name
        }
        await AccountsApi.addTreasuryBalanceVerifyTaskExplain(
          entityId.value,
          props.currentData?.treasuryBalancePeriodId,
          data
        )
        if (isEmpty(reason)) {
          accountStore.fetchTreasuryBalanceVerifyTaskReasonList(entityId.value)
        }
        ElMessage.success(t('message.success'))
        emit('onResetList')
        onCheckExplainDialog()
      } catch (error: any) {
        console.log(error)
        ElMessage.error(error.message)
      } finally {
        submitLoading.value = false
      }
    }
  })
}

/**
 * @description: 关闭解释弹窗
 */
const onCloseExplainDialog = () => {
  explanationReasonForm.value = {
    treasuryBalanceVerifyReasonId: ''
  }
}

defineExpose({ onCheckExplainDialog })

watch(
  () => showExplainDialog.value,
  async () => {
    if (showExplainDialog.value) {
      try {
        loading.value = true
        await accountStore.fetchTreasuryBalanceVerifyTaskReasonList(entityId.value)
      } catch (error: any) {
        console.log(error)
      } finally {
        loading.value = false
      }
    }
  },
  {
    immediate: true
  }
)
</script>

<style lang="scss">
.elv-treasury-balance-verification-explanation-dialog {
  width: 620px;
  min-height: 276px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border-bottom: 1px solid #edf0f3;

    .elv-treasury-balance-verification-explanation-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .elv-treasury-balance-verification-explanation-dialog-info {
    width: 100%;
    display: flex;
    padding: 12px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 3px;
    border: 1px solid #eaeee2;
    background: #f8fbf2;
    color: #1e2024;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 16px;

    span {
      font-weight: 700;
    }
  }

  .el-dialog__body {
    padding: 18px 24px 6px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    p {
      margin: 0;
    }

    .el-input {
      width: 572px;
      height: 44px;
      border-radius: 4px;

      &.is-disabled {
        background: #f9fafb;
      }
    }

    .el-input__inner {
      font-family: 'Plus Jakarta Sans';
      font-weight: 400;
      font-size: 14px;
      color: #0e0f11;
    }

    .el-input__wrapper {
      border-radius: 4px;
      border: 1px solid #dde1e6;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
      padding: 1px 12px;
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    .el-input:not(.is-disabled) .el-input__wrapper {
      &:hover {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }

      &.is_focus {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }
    }

    .el-form-item {
      margin-bottom: 16px;

      &:last-of-type {
        margin-bottom: 0px;
      }

      &.is-error {
        .el-input__wrapper {
          background: #faeee6;
          border: 1px solid #7e4a15;
        }

        .el-input__inner {
          color: #7e4a15;
        }

        .el-form-item__error {
          font-family: 'Plus Jakarta Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #7e4a15;
        }
      }

      .el-form-item__label {
        font-family: 'Plus Jakarta Sans';
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        text-transform: capitalize;
        color: #636b75;
      }
    }

    .el-select {
      .el-input.is-focus:not(.el-select--disabled) .el-input__wrapper {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }
    }

    .el-input__wrapper.is-focus {
      border: 1px solid #7596eb !important;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
    }

    .elv-reconciliation-explain-form-add-desc {
      display: flex;
      margin-top: 16px;
      font-family: Plus Jakarta Sans;
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      color: #636b75;

      svg {
        padding-top: 2px;
        margin-right: 4px;
      }
    }
  }

  .el-dialog__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
  }
}
</style>
